import '@kiwitaxi/vue-components/dist/styles/KwPreloader.css';

const STYLES = `
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: none;
`;

export default function () {
  return `
    <div class="kw-preloader" style="${STYLES}">
      <div class="kw-preloader__dot"></div>
      <div class="kw-preloader__dot"></div>
      <div class="kw-preloader__dot"></div>
    </div>
  `;
}

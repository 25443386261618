import getFrameMarkup from './getFrameMarkup';
import getPreloaderMarkup from './getPreloaderMarkup';
import { styles, classes } from './visual';

export default function (id) {
  return `
    <style>${styles}</style>
    <div
      id="${id}"
      class="${classes.modal}"
      aria-hidden="true"
    >
      <div class="${classes.overlay}">
        <div
          class="${classes.content}"
          role="dialog"
          aria-modal="true"
        >
          ${getPreloaderMarkup()}
          ${getFrameMarkup()}
        </div>
        <button
          class="${classes.close}"
          data-micromodal-close
          tabindex="-1"
        >
        </button>
      </div>
    </div>
  `;
}

import qs from 'qs';

const { VUE_APP_WIDGET_RU_URL, VUE_APP_WIDGET_INTERNATIONAL_URL } = process.env;
const QS_CONFIG = {
  skipNulls: true,
  addQueryPrefix: true,
};

function getKsrcp() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const allParameters = {
    utm_source: query.utm_source,
    utm_medium: query.utm_medium,
    utm_campaign: query.utm_campaign,
    utm_term: query.utm_term,
    utm_content: query.utm_content,
    krefp: query.krefp,
  };

  return Object.keys(allParameters)
    .filter(key => allParameters[key])
    .reduce((parameters, key) => {
      parameters[key] = allParameters[key];
      return parameters;
    }, {});
}

export default function (options = {}) {
  const { locale, path, skipCurrentUserAuthorization = false } = options;
  const base = locale === 'ru' ? VUE_APP_WIDGET_RU_URL : VUE_APP_WIDGET_INTERNATIONAL_URL;
  const query = qs.stringify({
    path,
    locale: locale || 'en',
    ksrcp: getKsrcp(),
    skipCurrentUserAuthorization,
  }, QS_CONFIG);

  return `${base}/${query}`;
}

const classPrefix = 'kiwitaxi-register-';
const modalWidth = '360px';

const getClass = name => `${classPrefix}${name}`;

export const classes = {
  close: getClass('close'),
  modal: getClass('modal'),
  overlay: getClass('overlay'),
  content: getClass('content'),
  frame: getClass('frame'),
};

export const styles = `
  .${classes.close} {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    transition: .5s opacity;
  }

  .${classes.close}:hover {
    opacity: .75;
  }

  .${classes.close}::before,
  .${classes.close}::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    background-color: white;
    top: 50%;
    margin-top: -1px;
    left: 12px;
  }

  .${classes.close}::before {
    transform: rotate(45deg);
  }

  .${classes.close}::after {
    transform: rotate(-45deg);
  }

  .${classes.modal} {
    display: none;
    z-index: 9999;
    position: relative;
  }

  .${classes.content} {
    max-width: ${modalWidth};
    width: 100%;
    margin: 48px auto;
    cursor: default;
    position: relative;
    min-height: 84px;
  }

  .${classes.overlay} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .${classes.frame} {
    width: 1px;
    min-width: 100%;
    border: 0;
    display: block;
    height: 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 8px 8px 0 #373a3c;
  }

  @media (max-width: ${modalWidth}) {
    .${classes.content} {
      margin-bottom: 0;
    }

    .${classes.frame} {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

import 'core-js/modules/es6.array.from';
import 'core-js/modules/es7.object.values';
import 'core-js/modules/es6.object.assign';
import 'element-remove';
import 'element-closest/browser';
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import MicroModal from 'micromodal';
import qs from 'qs';

import getUrl from './getUrl';
import getModalMarkup from './getModalMarkup';

const WIDGET_ID = process.env.VUE_APP_WIDGET_ID;
const LINK_SELECTOR = `a[href*=${WIDGET_ID}]`;
const IFRAME_RESIZE_SETTINGS = {
  checkOrigin: false,
  warningTimeout: 0,
};

const widgetRegister = {
  frame: null,
  preloader: null,
  isLoading: false,
  init() {
    this.addListeners();
  },
  setupModal() {
    const markup = getModalMarkup(WIDGET_ID);
    document.body.insertAdjacentHTML('beforeend', markup);

    const modal = document.getElementById(WIDGET_ID);
    this.frame = modal.querySelector('iframe');
    this.preloader = modal.querySelector('.kw-preloader');

    iFrameResize(IFRAME_RESIZE_SETTINGS, this.frame);

    // решение проблемы с загрузкой шрифтов (высчитывался неправильный размер при появлении)
    setInterval(() => {
      if (!this.isLoading) {
        this.frame.iFrameResizer.resize();
      }
    }, 1000);
  },
  showModal(src) {
    this.frame.src = src;

    MicroModal.show(WIDGET_ID, {
      disableScroll: true,
      onShow(modal) {
        modal.style.display = 'block';
      },
      onClose: (modal) => {
        modal.style.display = 'none';
        this.removeHash();
      },
    });
  },
  getUrlFromHash(hash) {
    const [, query] = hash.split('?');
    return getUrl(qs.parse(query));
  },
  showLoader() {
    this.isLoading = true;
    this.frame.style.display = 'none';
    this.preloader.style.display = 'block';
  },
  hideLoader() {
    this.frame.style.display = 'block';
    this.preloader.style.display = 'none';
    this.isLoading = false;
  },
  openModal(hash) {
    const url = this.getUrlFromHash(hash);

    this.showLoader();
    this.showModal(url);
  },
  checkUrl() {
    const { hash } = window.location;

    if (hash.includes(WIDGET_ID)) {
      this.openModal(hash);
    }
  },
  updateHash(parameters) {
    const { hash } = window.location;
    const [, query] = hash.split('?');
    const allParameters = { ...parameters };

    if (query) {
      Object.assign(allParameters, {
        ...qs.parse(query),
        ...parameters,
      });
    }

    this.setHash(`${WIDGET_ID}?${qs.stringify(allParameters)}`);
  },
  removeHash() {
    const { pathname, search } = window.location;
    window.history.replaceState(null, null, `${pathname}${search}`);
  },
  setHash(hash) {
    this.removeHash();
    window.history.replaceState(null, null, `#${hash}`);
  },
  onLoad() {
    this.setupModal();
    this.checkUrl();
  },
  onClick(e) {
    const { target } = e;
    const link = target.closest(LINK_SELECTOR);

    if (link) {
      e.preventDefault();

      const { href } = link;
      const [, hash] = href.split('#');

      this.openModal(hash);
      this.setHash(hash);
    }
  },
  onMessage(e) {
    const { type, data } = e.data || {};

    if (type === 'mounted') {
      this.hideLoader();
    }

    if (type === 'route-change') {
      this.updateHash({
        path: data.path.replace(/\//g, ''),
      });
    }
  },
  addListeners() {
    document.addEventListener('DOMContentLoaded', () => this.onLoad());
    document.addEventListener('click', e => this.onClick(e));
    window.addEventListener('message', e => this.onMessage(e));
  },
};

widgetRegister.init();
